import React, {useState, useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {confirmAlert} from 'react-confirm-alert'
import {useForm} from 'react-hook-form'
import useCategoriesHook from '../../../api/categories'
import {ErrorBoundary} from "react-error-boundary";
import {useNavigate} from 'react-router-dom';
import { ViewCategories } from '../../../components/admin/categories/ViewCategories';
import { FormCategories } from '../../../components/admin/categories/FormCategories';
// import useMachinesHook from '../../../api/machines';
import {checkImageFiles} from '../../../utils/imageTools';
import {useTranslation} from "react-i18next";

import {
    Spinner,
    Pagination,
    Message,
    Confirm,
} from '../../../components'
import {Button} from "@mui/material";

const Categories = () => {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [edit, setEdit] = useState(false)
    const [q, setQ] = useState('')

    const {getCategories, postCategory, updateCategory, deleteCategory} = useCategoriesHook({
        page,
        q,
    })

    // const {getMachines} = useMachinesHook({
    //     limit: 100000,
    //     page: 1,
    // })

    // const {data: dataMachines} = getMachines

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
        },
    })

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getCategories

    const {
        // data: responseUpdate,
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        error: errorUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate,
    } = updateCategory

    const {
        // data: responseDelete,
        isLoading: isLoadingDelete,
        isError: isErrorDelete,
        error: errorDelete,
        isSuccess: isSuccessDelete,
        mutateAsync: mutateAsyncDelete,
    } = deleteCategory

    const {
        // data: responsePost,
        isLoading: isLoadingPost,
        isError: isErrorPost,
        error: errorPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost,
    } = postCategory

    const formCleanHandler = (force = false) => {
        if (force || isSuccessPost || isSuccessUpdate) {
            console.log('clean handler true');
            setEdit(false);
            reset();
            setFileInputs([]); // Also reset the file inputs state
            setImageThumbnails([]); // Also reset the image thumbnails
        }
    }


    const [imageThumbnails, setImageThumbnails] = useState([]);
    const [imagesPath, setImagesPath] = useState({});



    useEffect(() => {
        if (isSuccessPost || isSuccessUpdate) formCleanHandler();
    }, [isSuccessPost, isSuccessUpdate])

    useEffect(() => {
        refetch()
    }, [page])

    useEffect(() => {
        if (!q) refetch()
    }, [q])

    useEffect(() => {
        if (!q) refetch()
    }, [q])


    const searchHandler = (e) => {
        e.preventDefault()
        refetch()
        setPage(1)
    }

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)))
    }
    const navigate = useNavigate();

    const [fileInputs, setFileInputs] = useState([]);


    const submitHandler = async (itemData) => {
        try {
            console.log('submitHandler');

            console.log(fileInputs);

            if (edit) {

                const allImages = checkImageFiles(fileInputs);
                await mutateAsyncUpdate({
                    _id: id,
                    category_id: itemData.category_id,
                    // category_name: itemData.category_name,
                    category_name: {
                        el: itemData.category_name_el,
                        en: itemData.category_name_en,
                    },

                    // machine: itemData.machine,
                    categoryItems: itemData.categoryItems,
                    images: allImages,
                });
                console.log('RESPONSE UPDATE')
            } else {

                // Check and prepare images
                const preparedImages = checkImageFiles(fileInputs);

                const payload = {
                    category_name: {
                        el: itemData.category_name_el,
                        en: itemData.category_name_en,
                    },
                    ...itemData,
                    images: preparedImages,
                };

                console.log('RESPONSE POST')
                await mutateAsyncPost(payload);
            }

            // Request succeeded
            if (edit) {
                console.log('SUCCESSFULL EDIT');
            } else {
                console.log('SUCCESSFULL NEW');
            }
        } catch (error) {
            // Request failed
            console.error(error);
            if (edit) {
                console.log('FAILED EDIT POST')

            } else {
                console.log('FAILED NEW POST')
            }
            if (error.response && error.response.status === 500) {
                console.log('FAILED POST 500')
            }
        }
    };

    const editHandler = (category) => {
        console.log('editHandler');
        console.log(category);
        setId(category._id)
        setEdit(true);
        setValue('category_id', category.category_id);
        // setValue('machine', category.machine);
        if(category.category_name && typeof category.category_name === 'object') {
            Object.keys(category.category_name).forEach(lang => {
                setValue(`category_name_${lang}`, category.category_name[lang]);
            });
        }
        // setValue('categoryItems', category.categoryItems);
        console.log('images');

        console.log(category.images);
        setImageThumbnails(category.images)
        setImagesPath(data.images_path);
        setValue('images', category.images);

    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Categories</title>
                    <meta property='og:title' content='Categories' key='title'/>
                </Helmet>
            </HelmetProvider>
            <ErrorBoundary
                FallbackComponent={() => (
                    <ErrorFallback goBack={() => navigate(-1)} error={isSuccessPost}/>
                )}
                onError={console.error}
            >
                {isErrorPost && <Message variant='danger'>{errorPost}</Message>}
                {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {isSuccessDelete && (
                    <Message variant='success'>Category has been deleted successfully.</Message>
                )}
                {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}
                {isSuccessUpdate && (
                    <Message variant='success'>Category has been updated successfully.</Message>
                )}
                {isSuccessPost && (
                    <Message variant='success'>Category has been Created successfully.</Message>
                )}


                <FormCategories
                    edit={edit}
                    formCleanHandler={formCleanHandler}
                    isLoading={isLoading}
                    isError={isError}
                    isErrorUpdate={isErrorUpdate}
                    errors={errors}
                    isLoadingUpdate={isLoadingUpdate}
                    isLoadingPost={isLoadingPost}
                    register={register}
                    handleSubmit={handleSubmit}
                    submitHandler={submitHandler}
                    watch={watch}
                    error={error}
                    setValue={setValue}
                    imageThumbnails={imageThumbnails}
                    fileInputs={fileInputs}
                    setFileInputs={setFileInputs}
                    // dataMachines={dataMachines}
                    images_path={imagesPath}
                    useTranslation={useTranslation}

                />

                <div className='ms-auto text-end'>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                {isLoading ? (
                    <Spinner/>
                ) : isError ? (
                    <Message variant='danger'>{error}</Message>
                ) : (
                    <ViewCategories
                        data={data}
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                        isLoadingDelete={isLoadingDelete}
                        setQ={setQ}
                        q={q}
                        formCleanHandler={formCleanHandler}
                        searchHandler={searchHandler}
                        setEdit={setEdit}
                        reset={reset}
                        useTranslation={useTranslation}
                        // images_path={imagesPath}
                    />
                )}
            </ErrorBoundary>

        </>
    )
}

function ErrorFallback({error, goBack}) {
    const errorMessage = error && error.message ? error.message : "Unknown error";

    return (
        <div>
            <div className="user-error-message-block">
                <p>Something went wrong 😭</p>
                <span>Error: {errorMessage}</span>
                <Button onClick={goBack}>Go Back</Button>
            </div>
        </div>
    );
}

export default Categories

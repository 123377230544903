import React, {useState, useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {confirmAlert} from 'react-confirm-alert'
import {useForm} from 'react-hook-form'
import useMachinesHook from '../../../api/machines'
import {ErrorBoundary} from "react-error-boundary";
import {useNavigate} from 'react-router-dom';
import { ViewMachines } from '../../../components/admin/machines/ViewMachines';
import { FormMachines } from '../../../components/admin/machines/FormMachines';
import useProductsHook from '../../../api/products';
import {
    Spinner,
    Pagination,
    Message,
    Confirm,
} from '../../../components'
import {Button} from "@mui/material";

const Machines = () => {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [edit, setEdit] = useState(false)
    const [q, setQ] = useState('')
    const [triggerReset, setTriggerReset] = useState(false);

    const {getMachines, postMachine, updateMachine, deleteMachine, getMachineTotalStockById, getMachineTotalPriceById} = useMachinesHook({
        page,
        q,
    })
    const {getProducts} = useProductsHook({
        limit: 100000,
        page: 1,
    })
    const {data: dataProducts} = getProducts

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            confirmed: true,
            blocked: false,
        },
    })

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getMachines

    const {
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        error: errorUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate,
    } = updateMachine

    const {
        isLoading: isLoadingDelete,
        isError: isErrorDelete,
        error: errorDelete,
        isSuccess: isSuccessDelete,
        mutateAsync: mutateAsyncDelete,
    } = deleteMachine

    const {
        isLoading: isLoadingPost,
        isError: isErrorPost,
        error: errorPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost,
    } = postMachine

    const formCleanHandler = (force = false) => {
        if (force || isSuccessPost || isSuccessUpdate) {
            setEdit(false);
            reset();
            setTriggerReset(prev => !prev);
        }
    }

    useEffect(() => {
        //if (isSuccessPost || isSuccessUpdate) formCleanHandler()
    }, [isSuccessPost, isSuccessUpdate])

    useEffect(() => {
        refetch()
    }, [page])

    useEffect(() => {
        if (!q) refetch()
    }, [q])

    const searchHandler = (e) => {
        e.preventDefault()
        refetch()
        setPage(1)
    }

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)))
    }
    const navigate = useNavigate();

    const submitHandler = async (data) => {
        try {
            // console.log(edit);
            if (edit) {
                await mutateAsyncUpdate({
                    _id: id,
                    kiosk_external_id: data.kiosk_external_id,
                    machine_name: data.machine_name,
                    machine_phone: data.machine_phone,
                    items: data.items ? JSON.parse(data.items) : [],
                    items_errors: data.items_errors ? JSON.parse(data.items_errors) : [],
                    pre_items: data.pre_items ? JSON.parse(data.pre_items) : [],
                    post_items: data.post_items ? JSON.parse(data.post_items) : [],
                    pre_items_errors: data.pre_items_errors ? JSON.parse(data.pre_items_errors) : [],
                    post_items_errors: data.post_items_errors ? JSON.parse(data.post_items_errors) : [],
                    machineType: data.machineType,
                    qr_code_receipt: data.qr_code_receipt,
                    machineToAdminInventory: data.machineToAdminInventory,
                    machineToAdminPlanogram: data.machineToAdminPlanogram,
                    payment_options: data.payment_options,
                    machine_status: data.machine_status,
                    machine_order_id_processing: data.machine_order_id_processing,
                    erp_id: data.erp_id,
                    erp_token: data.erp_token,
                    erp_doc_series: data.erp_doc_series,
                    confirmed: data.confirmed,
                    blocked: data.blocked,
                });
            } else {
                //console.log(data)

                await mutateAsyncPost({
                    ...data,
                    items: data.items || [],
                    machine_name: data.machine_name,
                    machine_phone: data.machine_phone,
                    items_errors: data.items_errors || [],
                    pre_items: data.pre_items || [],
                    post_items: data.post_items || [],
                    pre_items_errors: data.pre_items_errors || [],
                    post_items_errors: data.post_items_errors || [],
                    machineType: data.machineType,
                    payment_options: data.payment_options,
                    qr_code_receipt: data.qr_code_receipt,
                    machineToAdminInventory: data.machineToAdminInventory,
                    machineToAdminPlanogram: data.machineToAdminPlanogram,
                    machine_status: data.machine_status,
                    machine_order_id_processing: data.machine_order_id_processing,
                    erp_id: data.erp_id,
                    erp_token: data.erp_token,
                    erp_doc_series: data.erp_doc_series,
                    confirmed: data.confirmed,
                    blocked: data.blocked,
                });
            }

            // Request succeeded
            if (edit) {
                console.log('SUCCESSFULL EDIT POST');
            } else {
                console.log('SUCCESSFULL NEW POST');
            }
        } catch (error) {
            // Request failed
            console.error(error);
            if (edit) {
                console.log('FAILED EDIT POST')

            } else {
                console.log('FAILED NEW POST')
            }
            if (error.response && error.response.status === 500) {
                console.log('FAILED POST 500')
            }
        }
    };


    // const fetchTotalMachineStock = async (machineId) => {
    //     // Assuming you have a function similar to `dynamicAPI` for fetching
    //     try {
    //         const response = await dynamicAPI('get', `${url}/${machineId}/totalStock`, {});
    //         alert(`Total stock for machine ${machineId}: ${response.totalCurrentStock}`);
    //     } catch (error) {
    //         console.error("Failed to fetch total stock for machine", machineId, error);
    //         alert("Failed to fetch total stock");
    //     }
    // };


    const editHandler = (machine) => {
        setId(machine._id)
        setEdit(true)
        setValue('kiosk_external_id', machine.kiosk_external_id);
        setValue('machine_name', machine.machine_name);
        setValue('machine_phone', machine.machine_phone);
        setValue('items', machine.items ? JSON.stringify(machine.items) : '');
        setValue('items_errors', machine.items_errors ? JSON.stringify(machine.items_errors) : '');
        setValue('pre_items', machine.pre_items ? JSON.stringify(machine.pre_items) : '');
        setValue('post_items', machine.post_items ? JSON.stringify(machine.post_items) : '');
        setValue('pre_items_errors', machine.pre_items_errors ? JSON.stringify(machine.pre_items_errors) : '');
        setValue('post_items_errors', machine.post_items_errors ? JSON.stringify(machine.post_items_errors) : '');
        setValue('machine_order_id_processing', machine.machine_order_id_processing);
        setValue('coins_total_stock', machine.coins_total_stock);
        setValue('bills_stock', machine.bills_stock ? JSON.stringify(machine.bills_stock) : '');
        setValue('erp_id', machine.erp_id);
        setValue('erp_token', machine.erp_token);
        setValue('erp_doc_series', machine.erp_doc_series);
        setValue('machine_status', machine.machine_status);
        setValue('machineType', machine.machineType);
        setValue('qr_code_receipt', machine.qr_code_receipt);
        setValue('machineToAdminInventory', machine.machineToAdminInventory);
        setValue('machineToAdminPlanogram', machine.machineToAdminPlanogram);
        setValue('payment_options', machine.payment_options);
        setValue('confirmed', machine.confirmed);
        setValue('blocked', machine.blocked);
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Machines</title>
                    <meta property='og:title' content='Machines' key='title'/>
                </Helmet>
            </HelmetProvider>
            <ErrorBoundary
                FallbackComponent={() => (
                    <ErrorFallback goBack={() => navigate(-1)} error={isSuccessPost}/>
                )}
                onError={console.error}
            >
                {isErrorPost && <Message variant='danger'>{errorPost}</Message>}
                {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {isSuccessDelete && (
                    <Message variant='success'>Machine has been deleted successfully.</Message>
                )}
                {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}
                {isSuccessUpdate && (
                    <Message variant='success'>Machine has been updated successfully.</Message>
                )}
                {isSuccessPost && (
                    <Message variant='success'>Machine has been Created successfully.</Message>
                )}

                <FormMachines
                    edit={edit}
                    formCleanHandler={formCleanHandler}
                    isLoading={isLoading}
                    isError={isError}
                    errors={errors}
                    isLoadingUpdate={isLoadingUpdate}
                    isLoadingPost={isLoadingPost}
                    register={register}
                    handleSubmit={handleSubmit}
                    submitHandler={submitHandler}
                    watch={watch}
                    error={error}
                    dataProducts={dataProducts}
                    dataMachines={data}
                    setValue={setValue}
                    triggerReset={triggerReset}
                />

                <div className='ms-auto text-end'>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                {isLoading ? (
                    <Spinner/>
                ) : isError ? (
                    <Message variant='danger'>{error}</Message>
                ) : (
                    <ViewMachines
                        data={data}
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                        isLoadingDelete={isLoadingDelete}
                        getMachineTotalStockById={getMachineTotalStockById}
                        getMachineTotalPriceById={getMachineTotalPriceById}
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                        formCleanHandler={formCleanHandler}
                    />
                )}
            </ErrorBoundary>

        </>
    )
}

function ErrorFallback({error, goBack}) {
    const errorMessage = error && error.message ? error.message : "Unknown error";

    return (
        <div>
            <div className="user-error-message-block">
                <p>Something went wrong 😭</p>
                <span>Error: {errorMessage}</span>
                <Button onClick={goBack}>Go Back</Button>
            </div>
        </div>
    );
}

export default Machines

import dynamicAPI from './dynamicAPI'
import {useQuery, useMutation, useQueryClient} from 'react-query'

const url = '/api/auth/roles'

const queryKey = 'roles'

export default function useRolesHook(props) {
    const {page = 1, id, q = '', limit = 25} = props
    const queryClient = useQueryClient()

    const getRoles = useQuery(
        queryKey,
        async () =>
            await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`, {}),
        {retry: 0}
    )

    const getRoleById = useQuery(
        queryKey,
        async (id) => await dynamicAPI('get', `${url}/${id}`, {}),
        {retry: 0, enabled: !!id}
    )

    const updateRole = useMutation(
        async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
        {
            retry: 0,
            onSuccess: () => queryClient.invalidateQueries([queryKey]),
        }
    )

    const deleteRole = useMutation(
        async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
        {
            retry: 0,
            onSuccess: () => queryClient.invalidateQueries([queryKey]),
        }
    )

    const postRole = useMutation(
        async (obj) => await dynamicAPI('post', url, obj),
        {
            retry: 0,
            onSuccess: () => queryClient.invalidateQueries([queryKey]),
        }
    )

    return {
        getRoles,
        updateRole,
        deleteRole,
        postRole,
        getRoleById
    }
}

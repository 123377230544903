import { Link, useNavigate } from 'react-router-dom';
import { FaSignInAlt, FaUser } from 'react-icons/fa';
import useAuthHook from '../../api/auth';
import useAuth from '../../hooks/useAuth';
import LanguageSwitcher from "./LanguageSwitcher";
import useUserCategoriesHook from '../../api/userCategories';
import { useMutation } from 'react-query';

const Navigation = () => {
    const navigate = useNavigate();
    const { postLogout } = useAuthHook();
    const { auth, setAuth } = useAuth();

    // Use the user categories hook to fetch categories
    const { getGuestCategories, getAdminCategories } = useUserCategoriesHook({});

    // Fetch appropriate categories based on auth state
    const { data: categoriesData, isLoading, isError } = auth?.userInfo
        ? getAdminCategories
        : getGuestCategories;

    const { mutateAsync: logout } = useMutation(postLogout, {
        onSuccess: () => {
            setAuth(null);
            navigate('/admin/auth/login');
        },
    });

    const handleLogout = () => {
        logout({});
    };

    const user = () => {
        return auth?.userInfo;
    };

    const organizeMenus = () => {
        const categories = categoriesData?.categories || [];

        // Safely map categories, ensuring each item has a menu property
        const dropdownItems = categories.map((category) => category.menu || '');
        const uniqueDropdowns = [...new Set(dropdownItems.filter(item => item && item !== 'hidden' && item !== 'normal'))];

        const menuItems = categories.map((category) => category);

        return { uniqueDropdowns, menuItems };
    };

    const renderAuthItems = () => {
        const { uniqueDropdowns, menuItems } = organizeMenus();

        return (
            <ul className='navbar-nav ms-auto'>
                {menuItems.map(
                    (menu) =>
                        menu.menu === 'normal' &&
                        menu.auth === true && (
                            <li key={menu._id} className='nav-item'>
                                <Link to={menu.path} className='nav-link' aria-current='page'>
                                    {menu.name}
                                </Link>
                            </li>
                        )
                )}

                {uniqueDropdowns.map((item) => (
                    item && (
                        <li key={item} className='nav-item dropdown'>
                            <a
                                className='nav-link dropdown-toggle'
                                id='navbarDropdownMenuLink'
                                role='button'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                            >
                                {item === 'profile' ? (
                                    <>
                                        <FaUser className='me-1 menu-navigation-icon navigation-profile-icon' />
                                        {user()?.name}
                                    </>
                                ) : (
                                    item.charAt(0).toUpperCase() + item.substring(1)
                                )}
                            </a>
                            <ul className='dropdown-menu border-0' aria-labelledby='navbarDropdownMenuLink'>
                                {menuItems.map(
                                    (menu) =>
                                        menu.menu === item && (
                                            <li key={menu._id}>
                                                <Link to={menu.path} className='dropdown-item'>
                                                    {menu.name}
                                                </Link>
                                            </li>
                                        )
                                )}
                                {item === 'profile' && (
                                    <li>
                                        <Link
                                            to='/admin/auth/login'
                                            className='dropdown-item logout-menu-item'
                                            onClick={handleLogout}
                                        >
                                            Logout
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )
                ))}
            </ul>
        );
    };

    const renderGuestItems = () => (
        <ul className='navbar-nav ms-auto'>
            <li className='nav-item'>
                <Link to='/admin/auth/login' className='nav-link' aria-current='page'>
                    <FaSignInAlt className='mb-1' /> Login
                </Link>
            </li>
        </ul>
    );

    if (isLoading) {
        return <div>Loading...</div>; // Loading state while fetching categories
    }

    if (isError) {
        return <div>Error loading categories</div>; // Error state if fetching categories fails
    }

    return (
        <nav className='navbar navbar-expand-md navbar-light bg-light'>
            <div className='container'>
                <Link to='/admin'>
                    <img
                        width='100'
                        height='100'
                        src='/img/imall_logo.png'
                        className='img-fluid brand-logos'
                        alt='logo'
                    />
                </Link>
                <button
                    className='navbar-toggler'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#navbarNav'
                    aria-controls='navbarNav'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                >
                    <span className='navbar-toggler-icon'></span>
                </button>
                <div className='collapse navbar-collapse' id='navbarNav'>
                    {auth?.userInfo ? renderAuthItems() : renderGuestItems()}
                </div>
                <LanguageSwitcher />
            </div>
        </nav>
    );
};

export default Navigation;

import { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormContainer } from '../../components';
import { useForm } from 'react-hook-form';
import useAuthHook from '../../api/auth';
import useAuth from '../../hooks/useAuth';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../context/AdminMessageContext';
import { inputEmail, inputPassword } from '../../utils/dynamicForm';
import useUserCategoriesHook from '../../api/userCategories'; // Import for refetching categories

const Login = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const pathName = searchParams.get('next') || '/admin';
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { postLogin } = useAuthHook();
  const { setSuccessMessage, setErrorMessage } = useMessage();
  const { getAdminCategories } = useUserCategoriesHook({}); // Hook for admin categories
  const { refetch: refetchAdminCategories } = getAdminCategories; // Extract refetch function

  const {
    mutateAsync: loginMutateAsync,
    isLoading: isLoginLoading,
  } = postLogin;

  useEffect(() => {
    if (auth?.userInfo) {
      navigate('/');
    }
  }, [auth?.userInfo, navigate]);

  const submitHandler = async (data) => {
    try {
      const response = await loginMutateAsync(data);

      if (response?.token) {  // Check if a valid token is returned
        // Store login data (including token) to local storage
        localStorage.setItem('userInfo', JSON.stringify(response));

        // Set auth state with login data
        setAuth({ userInfo: response });

        // Refetch admin categories after successful login
        await refetchAdminCategories();

        setSuccessMessage('Login successful!');
        navigate(pathName);
      } else {
        // If the response does not include a token, consider it a failure
        const errorMessage = response?.error || 'Login failed. Please try again.';
        setErrorMessage(errorMessage);
        console.error('Login failed:', errorMessage);
      }
    } catch (error) {
      // Handle exceptions and unexpected errors
      const errorMessage = error?.response?.data?.error || 'Login failed due to an unexpected error. Please try again.';
      setErrorMessage(errorMessage);
      console.error('Login failed:', errorMessage);
    }
  };

  return (
      <FormContainer>
        <HelmetProvider>
          <Helmet>
            <title>Login</title>
            <meta property='og:title' content='Login' key='title' />
          </Helmet>
        </HelmetProvider>
        <h3 className='fw-light font-monospace text-center'>Sign In</h3>

        <form onSubmit={handleSubmit(submitHandler)}>
          {inputEmail({
            register,
            errors,
            label: 'Email',
            name: 'email',
            placeholder: 'Email',
          })}
          {inputPassword({
            register,
            errors,
            label: 'Password',
            name: 'password',
            placeholder: 'Password',
          })}
          <button
              type='submit'
              className='btn btn-primary form-control'
              disabled={isLoginLoading}
          >
            {isLoginLoading ? (
                <span className='spinner-border spinner-border-sm' />
            ) : (
                <div>{t('sign_in')}</div>
            )}
          </button>
        </form>
        <div className='row pt-3'>
          <div className='col'>
            <Link to='/admin/auth/forgot-password' className='ps-1'>
              Forgot Password?
            </Link>
          </div>
        </div>
      </FormContainer>
  );
};

export default Login;

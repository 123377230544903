import React, {useState, useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {confirmAlert} from 'react-confirm-alert'
import {useForm} from 'react-hook-form'
import useOrdersHook from '../../../api/orders'
import {ErrorBoundary} from "react-error-boundary";
import {useNavigate} from 'react-router-dom';
import {ViewOrders} from '../../../components/admin/orders/ViewOrders';
import {FormOrders} from '../../../components/admin/orders/FormOrders';
import useMachinesHook from '../../../api/machines';
import {
    Spinner,
    Pagination,
    Message,
    Confirm,
} from '../../../components'
import {Button} from "@mui/material";

const Orders = () => {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [edit, setEdit] = useState(false)
    const [q, setQ] = useState('')

    const {getOrders, postOrder, updateOrder, deleteOrder} = useOrdersHook({
        page,
        q,
    })

    const {getMachines} = useMachinesHook({
        limit: 100000,
        page: 1,
    })

    const {data: dataMachines} = getMachines

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            cartState: 'IDLE'
        },
    })

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getOrders

    const {
        // data: responseUpdate,
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        error: errorUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate,
    } = updateOrder

    const {
        // data: responseDelete,
        isLoading: isLoadingDelete,
        isError: isErrorDelete,
        error: errorDelete,
        isSuccess: isSuccessDelete,
        mutateAsync: mutateAsyncDelete,
    } = deleteOrder

    const {
        // data: responsePost,
        isLoading: isLoadingPost,
        isError: isErrorPost,
        error: errorPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost,
    } = postOrder

    const formCleanHandler = () => {
        if (isSuccessPost || isSuccessUpdate) {
            setEdit(false);
            reset();
        }
    }

    useEffect(() => {
        if (isSuccessPost || isSuccessUpdate) formCleanHandler()
    }, [isSuccessPost, isSuccessUpdate])

    useEffect(() => {
        refetch()
    }, [page])

    useEffect(() => {
        if (!q) refetch()
    }, [q])

    useEffect(() => {
        if (!q) refetch()
    }, [q])


    const searchHandler = (e) => {
        e.preventDefault()
        refetch()
        setPage(1)
    }

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)))
    }
    const navigate = useNavigate();


    const submitHandler = async (itemData) => {
        try {
            console.log('submitHandler');

            // console.log('the_array:', imageData);
            // console.log(itemData);
            if (edit) {

                await mutateAsyncUpdate({
                    _id: id,
                    order_id: itemData.order_id,
                    machine: itemData.machine,
                    orderItems: itemData.orderItems,
                    paymentMethod: itemData.paymentMethod,
                    totalQuantity: itemData.totalQuantity,
                    totalPrice: itemData.totalPrice,
                    cartState: itemData.cartState,
                    transactionStatus: itemData.transactionStatus,
                    transactionStatusMessage: itemData.transactionStatusMessage,
                    orderTime: itemData.orderTime,
                    synced_status: itemData.synced_status,
                    synced_at: itemData.synced_at,
                    synced_id: itemData.synced_id,
                    qr_code_data: itemData.qr_code_data
                });
                console.log('RESPONSE UPDATE')
            } else {
                console.log('RESPONSE POST')

                await mutateAsyncPost(itemData);
            }

            // Request succeeded
            if (edit) {
                console.log('SUCCESSFULL EDIT');
            } else {
                console.log('SUCCESSFULL NEW');
            }
        } catch (error) {
            // Request failed
            console.error(error);
            if (edit) {
                console.log('FAILED EDIT POST')

            } else {
                console.log('FAILED NEW POST')
            }
            if (error.response && error.response.status === 500) {
                console.log('FAILED POST 500')
            }
        }
    };

    const editHandler = (order) => {
        console.log('editHandler');
        console.log(order);
        setId(order._id)
        setEdit(true);
        setValue('order_id', order.order_id);
        setValue('machine', order.machine);
        setValue('orderItems', order.orderItems);
        setValue('paymentMethod', order.paymentMethod);
        setValue('totalQuantity', order.totalQuantity);
        setValue('totalPrice', order.totalPrice);
        setValue('cartState', order.cartState);
        setValue('transactionStatus', order.transactionStatus);
        setValue('transactionStatusMessage', order.transactionStatusMessage);
        setValue('orderTime', order.orderTime);
        setValue('synced_status', order.synced_status);
        setValue('synced_at', order.synced_at);
        setValue('synced_id', order.synced_id);
        setValue('qr_code_data', order.qr_code_data);
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Orders</title>
                    <meta property='og:title' content='Orders' key='title'/>
                </Helmet>
            </HelmetProvider>
            <ErrorBoundary
                FallbackComponent={() => (
                    <ErrorFallback goBack={() => navigate(-1)} error={isSuccessPost}/>
                )}
                onError={console.error}
            >
                {isErrorPost && <Message variant='danger'>{errorPost}</Message>}
                {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {isSuccessDelete && (
                    <Message variant='success'>Order has been deleted successfully.</Message>
                )}
                {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}
                {isSuccessUpdate && (
                    <Message variant='success'>Order has been updated successfully.</Message>
                )}
                {isSuccessPost && (
                    <Message variant='success'>Order has been Created successfully.</Message>
                )}


                <FormOrders
                    edit={edit}
                    formCleanHandler={formCleanHandler}
                    isLoading={isLoading}
                    isError={isError}
                    isErrorUpdate={isErrorUpdate}
                    errors={errors}
                    isLoadingUpdate={isLoadingUpdate}
                    isLoadingPost={isLoadingPost}
                    register={register}
                    handleSubmit={handleSubmit}
                    submitHandler={submitHandler}
                    watch={watch}
                    error={error}
                    dataMachines={dataMachines}
                />

                <div className='ms-auto text-end'>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                {isLoading ? (
                    <Spinner/>
                ) : isError ? (
                    <Message variant='danger'>{error}</Message>
                ) : (
                    <ViewOrders
                        data={data}
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                        isLoadingDelete={isLoadingDelete}
                        setQ={setQ}
                        q={q}
                        formCleanHandler={formCleanHandler}
                        searchHandler={searchHandler}
                        // images_path={imagesPath}
                    />
                )}
            </ErrorBoundary>

        </>
    )
}

function ErrorFallback({error, goBack}) {
    const errorMessage = error && error.message ? error.message : "Unknown error";

    return (
        <div>
            <div className="user-error-message-block">
                <p>Something went wrong 😭</p>
                <span>Error: {errorMessage}</span>
                <Button onClick={goBack}>Go Back</Button>
            </div>
        </div>
    );
}

export default Orders

import dynamicAPI from './dynamicAPI'
import {useQuery, useMutation, useQueryClient} from 'react-query'

const url = '/api/auth/userMachines'

const queryKey = 'userMachines'

export default function useUserMachinesHook(props) {
    const {page = 1, id, q = '', limit = 25} = props
    const queryClient = useQueryClient()

    const getUserMachines = useQuery(
        queryKey,
        async () =>
            await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`, {}),
        {retry: 0}
    )

    const getUserMachineById = useQuery(
        queryKey,
        async (id) => await dynamicAPI('get', `${url}/${id}`, {}),
        {retry: 0, enabled: !!id}
    )

    const updateUserMachine = useMutation(
        async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
        {
            retry: 0,
            onSuccess: () => queryClient.invalidateQueries([queryKey]),
        }
    )

    const deleteUserMachine = useMutation(
        async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
        {
            retry: 0,
            onSuccess: () => queryClient.invalidateQueries([queryKey]),
        }
    )

    const postUserMachine = useMutation(
        async (obj) => {
            const response = await dynamicAPI('post', url, obj);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        {
            retry: 0,
            onSuccess: () => queryClient.invalidateQueries([queryKey]),
        }
    )

    return {
        getUserMachines,
        updateUserMachine,
        deleteUserMachine,
        postUserMachine,
        getUserMachineById
    }
}

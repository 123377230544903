// import moment from 'moment'
import { FaPenAlt, FaTrash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { Search } from '../../index'
import DefaultMachinesIcon from "../../../media/vending-machine.svg";
import ReFetchIcon from "../../../media/re-fetch.svg";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import moment from 'moment'

export const ViewMachines = ({
  data,
  editHandler,
  deleteHandler,
  formCleanHandler,
  isLoadingDelete,
   getMachineTotalStockById,
   getMachineTotalPriceById,
  setQ,
  q,
  searchHandler,
}) => {

  const { t} = useTranslation(); // Use i18n instance to access current language

  // console.log(data);
  const [stockValues, setStockValues] = useState({}); // State to hold stock values keyed by machine ID
  const [priceValues, setPriceValues] = useState({}); // State to hold prices values keyed by machine ID

  const handleFetchAndDisplayStock = async (machineId) => {
    const response = await getMachineTotalStockById(machineId);
    setStockValues(prev => ({...prev, [machineId]: response.totalCurrentStock}));
  };
  const handleFetchAndDisplayPrice = async (machineId) => {
    const response = await getMachineTotalPriceById(machineId);
    setPriceValues(prev => ({...prev, [machineId]: response.totalCurrentPrice}));
  };
  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        <h3 className='fw-light text-muted'>
          {t("Machines List")} <sup className='fs-6'> [{data && data.total}] </sup>
          <img className="machines-list-title-icon admin-list-title-icon" src={DefaultMachinesIcon} alt="Default Marker Icon" />
        </h3>
        <button
          className='btn btn-outline-primary btn-sm shadow my-2'
          data-bs-toggle='modal'
          data-bs-target='#machineModal'
          onClick={() => formCleanHandler(true)}
        >
          {t("Add New Machine")}
        </button>
        <div className='col-auto'>
          <Search
            placeholder='Search by Code'
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
        </div>
      </div>
      <table className='table table-sm table-border edit-grid-view'>
        <thead className='border-0'>
        <tr>
          {/*<th>Created</th>*/}
          <th>Kiosk External ID</th>
          <th>Friendly Name</th>
          {/*<th>Type</th>*/}
          {/*<th>Items</th>*/}
          <th>Order Processing ID</th>
          {/*<th>Items Errors</th>*/}
          {/*<th>Pre Items</th>*/}
          {/*<th>Post Items</th>*/}
          {/*<th>Pre Items Errors</th>*/}
          {/*<th>Post Items Errors</th>*/}
          <th>{t("Total Stock")}</th>
          <th>{t("Total Value")}</th>
          <th>Machine Status</th>
          <th>MAGEX Activity</th>
          <th>Kiosk Activity</th>
          <th>Enabled</th>
          {/*<th>Blocked</th>*/}
          <th>{t("Actions")}</th>
        </tr>
        </thead>

        <tbody>
        {data && data.data ? (
            data.data.map((machine) => {

                  const machineIsOlderThanFiveMinutes = moment().diff(moment(machine.last_machine_connection), 'minutes') > 5;
                  const connectionMachineActivityClass = machineIsOlderThanFiveMinutes ? 'outdated-connection' : '';
                  const kioskIsOlderThanFiveMinutes = moment().diff(moment(machine.last_kiosk_activity), 'minutes') > 5;
                  const connectionKioskActivityClass = kioskIsOlderThanFiveMinutes ? 'outdated-connection' : '';


                  return (
                      <tr key={machine._id}
                          className={(machine.title === '' || machine.date === '' || machine.address === '') ? 'empty-values' : ''}>
                        {/*<td>{moment(machine.createdAt).format('lll')}</td>*/}
                        <td>{machine.kiosk_external_id}</td>
                        <td>{machine.machine_name}</td>
                        {/*<td>{machine.type}</td>*/}
                        {/*<td>{JSON.stringify(machine.items)}</td>*/}
                        <td>{machine.machine_order_id_processing}</td>
                        <td onClick={() => handleFetchAndDisplayStock(machine._id)}>
                          {
                            stockValues[machine._id] !== undefined ?
                                <div className="total-stock-machine">{stockValues[machine._id]}</div> :
                                <div className="fetch-total-stock">
                                  <img className="refetch-icon" src={ReFetchIcon} alt="Fetch Icon"/>
                                </div>
                          }
                        </td>
                        <td onClick={() => handleFetchAndDisplayPrice(machine._id)}>
                          {
                            priceValues[machine._id] !== undefined ?
                                <div className="total-price-machine">{priceValues[machine._id]}</div> :
                                <div className="fetch-total-price">
                                  <img className="refetch-icon" src={ReFetchIcon} alt="Fetch Icon"/>
                                </div>
                          }
                        </td>
                        {/*<td>{JSON.stringify(machine.items_errors)}</td>*/}
                        {/*<td>{JSON.stringify(machine.pre_items)}</td>*/}
                        {/*<td>{JSON.stringify(machine.post_items)}</td>*/}
                        {/*<td>{JSON.stringify(machine.pre_items_errors)}</td>*/}
                        {/*<td>{JSON.stringify(machine.post_items_errors)}</td>*/}
                        <td>{machine.machine_status}</td>
                        <td className={connectionMachineActivityClass}>{moment(machine.last_machine_connection).format('MMM D, h:mm A')}</td>
                        <td className={connectionKioskActivityClass}>{moment(machine.last_kiosk_activity).format('MMM D, h:mm A')}</td>
                        <td>
                          {machine.confirmed ? (
                              <FaCheckCircle className='text-success'/>
                          ) : (
                              <FaTimesCircle className='text-danger'/>
                          )}
                        </td>
                        {/*<td>*/}
                        {/*  {machine.blocked ? (*/}
                        {/*    <FaCheckCircle className='text-success' />*/}
                        {/*  ) : (*/}
                        {/*    <FaTimesCircle className='text-danger' />*/}
                        {/*  )}*/}
                        {/*</td>*/}

                        <td>
                          <div className='btn-group'>
                            <button
                                className='btn btn-primary btn-sm rounded-pill'
                                onClick={() => editHandler(machine)}
                                data-bs-toggle='modal'
                                data-bs-target='#machineModal'
                            >
                              <FaPenAlt/>
                            </button>

                            <button
                                className='btn btn-danger btn-sm ms-1 rounded-pill'
                                onClick={() => deleteHandler(machine._id)}
                                disabled={isLoadingDelete}
                            >
                              {isLoadingDelete ? (
                                  <span className='spinner-border spinner-border-sm'/>
                              ) : (
                                  <span>
                          <FaTrash/>
                        </span>
                              )}
                            </button>
                          </div>
                        </td>
                      </tr>
                  )
                }
            )) : (
            // Render an error message when data is undefined or has no data
            <tr>
              <td colSpan="8">Error: Failed to fetch machine data</td>
            </tr>
        )}
        </tbody>
      </table>
    </div>
  )
}

export default ViewMachines

// /* global dataLayer */
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {GoogleTagsKey} from "../../config/client-config";

const Footer = () => {
  // const date = new Date()
  // const currentYear = date.getFullYear()

    useEffect(() => {
        ReactGA.initialize(GoogleTagsKey);
        ReactGA.send({
            page: window.location.pathname
        });

    // If you want to track events, uncomment the following lines
    // ReactGA.event({
        //     category: "category",
        //     action: "action",
        //     label: "label", // optional
        //     value: 99, // optional, must be a number
        //     nonInteraction: true, // optional, true/false
        //     transport: "xhr", // optional, beacon/xhr/image
        // });

    }, []);

  return (
    <footer className='text-primary container-fluid'>
      <div className='row'>
        <div className='col text-center py-1 footer font-monospace bg-light my-auto'>
            <div className='footer-copyrights'>
                © {new Date().getFullYear()} iMall - v0.55 | Licensed under CC.
            </div>
            <div className='footer-developed-by'>
            {/* Copyright {currentYear} &copy; All Rights Reserved -  */}
              Developed by{' '}
              <a target='_blank' className='developed-by-link' href='https://softweb.gr' rel='noreferrer'>
                  softweb.gr
              </a>
              <br />
             {/*<img src='/demo_logo.gif' width='50' height='50' alt='logo' />*/}
            </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

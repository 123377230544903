import moment from 'moment';
import { FaPenAlt, FaTrash } from 'react-icons/fa';
import { Search } from '../../index';
import DefaultUserMachinesIcon from "../../../media/vending-machine.svg";

export const ViewUserMachines = ({
                                     data,
                                     editHandler,
                                     deleteHandler,
                                     formCleanHandler,
                                     isLoadingDelete,
                                     setQ,
                                     q,
                                     searchHandler,
                                 }) => {
    return (
        <div className='table-responsive bg-light p-3 mt-2'>
            <div className='d-flex align-items-center flex-column mb-2'>
                <h3 className='fw-light text-muted'>
                    UserMachines List <sup className='fs-6'> [{data && data.total}] </sup>
                    <img
                        className="usermachines-list-title-icon admin-list-title-icon"
                        src={DefaultUserMachinesIcon}
                        alt="Default Marker Icon"
                    />
                </h3>
                <button
                    className='btn btn-outline-primary btn-sm shadow my-2'
                    data-bs-toggle='modal'
                    data-bs-target='#userMachineModal'
                    onClick={() => formCleanHandler()}
                >
                    Add new UserMachine
                </button>
                <div className='col-auto'>
                    <Search
                        placeholder='Search by User / Machine'
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>
            <table className='table table-sm table-border edit-grid-view'>
                <thead className='border-0'>
                <tr>
                    <th>Created</th>
                    <th>User</th>
                    <th>Machines</th>
                    <th>Actions</th>
                </tr>
                </thead>

                <tbody>
                {data && data.data ? (
                    data.data.map((userMachine) => (
                        <tr key={userMachine._id}>
                            <td className="order-td">{moment(userMachine.createdAt).format('lll')}</td>
                            <td className="order-td">{userMachine.user.name}</td>
                            <td className="order-td">
                                {userMachine.machines && userMachine.machines.length > 0 ? (
                                    userMachine.machines.map((machine) => (
                                        <div key={machine._id}>
                                            {machine.machine_name + " : " + machine.kiosk_external_id}
                                        </div>
                                    ))
                                ) : (
                                    <div>No Machines Assigned</div>
                                )}
                            </td>
                            <td>
                                <div className='btn-group'>
                                    <button
                                        className='btn btn-primary btn-sm rounded-pill'
                                        onClick={() => editHandler(userMachine)}
                                        data-bs-toggle='modal'
                                        data-bs-target='#userMachineModal'
                                    >
                                        <FaPenAlt />
                                    </button>

                                    <button
                                        className='btn btn-danger btn-sm ms-1 rounded-pill'
                                        onClick={() => deleteHandler(userMachine._id)}
                                        disabled={isLoadingDelete}
                                    >
                                        {isLoadingDelete ? (
                                            <span className='spinner-border spinner-border-sm' />
                                        ) : (
                                            <span>
                                                    <FaTrash />
                                                </span>
                                        )}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="8">Error: Failed to fetch User Machines data</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default ViewUserMachines;

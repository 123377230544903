import { Helmet, HelmetProvider } from 'react-helmet-async'

const Home = () => {
  return (
    <>
        <HelmetProvider>
            <Helmet>
            <title>I-mall Project</title>
            <meta property='og:title' content='Home Page' key='title' />
          </Helmet>
        </HelmetProvider>
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ height: '70vh' }}
      >
        <span className='text-muted'>
            <h3 className='home-animation-h3'>
              <span className='home-animation-span'>i-mall</span>
              <span className='home-animation-span'>Project</span>
            </h3>
        </span>
      </div>
    </>
  )
}

export default Home

import moment from 'moment'
import { FaPenAlt, FaTrash  } from 'react-icons/fa'
import { Search } from '../../index'
import DefaultOrdersIcon from "../../../media/vending-machine.svg";

export const ViewOrders = ({
                             data,
                             editHandler,
                             deleteHandler,
                             formCleanHandler,
                             isLoadingDelete,
                             setQ,
                             q,
                             searchHandler,
                             // images_path
                           }) => {

  // console.log(data);
  return (
      <div className='table-responsive bg-light p-3 mt-2'>
        <div className='d-flex align-items-center flex-column mb-2'>
          <h3 className='fw-light text-muted'>
            Orders List <sup className='fs-6'> [{data && data.total}] </sup>
            <img className="orders-list-title-icon admin-list-title-icon" src={DefaultOrdersIcon} alt="Default Marker Icon" />
          </h3>
          <button
              className='btn btn-outline-primary btn-sm shadow my-2'
              data-bs-toggle='modal'
              data-bs-target='#orderModal'
              onClick={() => formCleanHandler()}
          >
            Add New Order
          </button>
          <div className='col-auto'>
            <Search
                placeholder='Search by id,status'
                setQ={setQ}
                q={q}
                searchHandler={searchHandler}
            />
          </div>
        </div>
        <table className='table table-sm table-border edit-grid-view'>
          <thead className='border-0'>
          <tr>
            <th>Created</th>
            <th>machine</th>
            <th>order_id</th>
            <th>erp_doc</th>
            {/*<th>orderItems</th>*/}
            {/*<th>Pre Items</th>*/}
            {/*<th>Post Items</th>*/}
            {/*<th>Pre Items Errors</th>*/}
            {/*<th>Post Items Errors</th>*/}
            {/*<th>paymentMethod</th>*/}
            <th>Total Quantity</th>
            <th>Total Price</th>
            <th>cartState</th>
            <th>Transaction Status</th>
            <th>Transaction Message</th>
            {/*<th>orderTime</th>*/}
            <th>Actions</th>
          </tr>
          </thead>

          <tbody>
          {data && data.data ? (
              data.data.map((order) => (
                  <tr key={order._id}
                      className={(order.title === '' || order.date === '' || order.address === '') ? 'empty-values' : ''}>
                    <td className="order-td">{moment(order.createdAt).format('lll')}</td>
                    <td className="order-td">{order.machine_id}</td>

                    <td className="order-td order-id">{order.order_id}</td>
                    <td className="order-td">{order.synced_id}</td>
                    {/*<td>{JSON.stringify(order.orderItems)}</td>*/}
                    {/*<td>{JSON.stringify(order.items_errors)}</td>*/}
                    {/*<td>{JSON.stringify(order.pre_items)}</td>*/}
                    {/*<td>{JSON.stringify(order.post_items)}</td>*/}
                    {/*<td>{JSON.stringify(order.pre_items_errors)}</td>*/}
                    {/*<td>{JSON.stringify(order.post_items_errors)}</td>*/}
                    {/*<td>{order.paymentMethod}</td>*/}
                    <td className="order-td">{order.totalQuantity}</td>
                    <td className="order-td">{order.totalPrice}</td>
                    <td className="order-td" data-status={order.cartState}>{order.cartState}</td>
                    <td className="order-td" data-status={order.transactionStatus}>{order.transactionStatus}</td>
                    <td className="order-td">{order.transactionStatusMessage}</td>
                    {/*<td className="order-td">{formatOrderTime(order.orderTime)}</td>*/}

                    <td>
                      <div className='btn-group'>
                        <button
                            className='btn btn-primary btn-sm rounded-pill'
                            onClick={() => editHandler(order)}
                            data-bs-toggle='modal'
                            data-bs-target='#orderModal'
                        >
                          <FaPenAlt/>
                        </button>

                        <button
                            className='btn btn-danger btn-sm ms-1 rounded-pill'
                            onClick={() => deleteHandler(order._id)}
                            disabled={isLoadingDelete}
                        >
                          {isLoadingDelete ? (
                              <span className='spinner-border spinner-border-sm'/>
                          ) : (
                              <span>
                          <FaTrash/>
                        </span>
                          )}
                        </button>
                      </div>
                    </td>
                  </tr>
              ))) : (
              // Render an error message when data is undefined or has no data
              <tr>
                <td colSpan="8">Error: Failed to fetch order data</td>
              </tr>
          )}
          </tbody>
        </table>
      </div>
  )
}

export default ViewOrders

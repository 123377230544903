import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth?.userInfo) {
    // User is not authenticated, redirect to login with the intended destination
    return <Navigate to={`/auth/login?next=${location.pathname}`} state={{ from: location }} replace />;
  }

  // User is authenticated, allow access to the route
  return <Outlet />;
};

export default RequireAuth;

import axios from 'axios';
import {config} from '../utils/customLocalStorage';

const apiUrl = process.env.REACT_APP_BACKEND_URL || "https://not_set";
// const apiUrl = process.env.REACT_APP_BACKEND_URL || "https://devapi-imall.dd.softwebpages.com";

const dynamicAPI = async (method, url, obj = {}) => {

    try {

        switch (method) {
            case 'get':
                return await axios
                    .get(`${apiUrl}${url}`, config())
                    .then((res) =>
                        ({
                            ...res.data,
                            status: res.status,
                            isSuccess: true
                        })
                    )

            case 'post':
                return await axios
                    .post(`${apiUrl}${url}`, obj, config())
                    .then((res) =>
                        ({
                            ...res.data,
                            status: res.status,
                            isSuccess: true
                        })
                    )

            case 'put':
                return await axios
                    .put(`${apiUrl}${url}`, obj, config())
                    .then((res) =>
                        ({
                            ...res.data,
                            status: res.status,
                            isSuccess: true
                        })
                    )

            case 'delete':
                return await axios
                    .delete(`${apiUrl}${url}`, config())
                    .then((res) =>
                        ({
                            ...res.data,
                            status: res.status,
                            isSuccess: true
                        })
                    )
            default:
                throw new Error('Invalid method');
        }
    } catch (error) {
        if (error.response) {
            // The server responded with an error
            // console.error(error.response)

            // console.error('SERVER ERROR:' + error.response.data.error)
            // throw new Error(error.response)
            // console.error(error)


            if (error.response.status === 401) {
                console.error('UNAUTHORIZED ERROR:' + error.response.data.error)

                localStorage.removeItem('userInfo');
                localStorage.removeItem('userRole');

                // window.location.reload();
                // setAuth(null);

                // We pass it only in cases we want to redirect the user, for example on permissions page we force the user but on users edit we don't want to lose the data"
                // if (navigate) {
                //     // navigate('/auth/login');
                // }

                return {
                    error: error.response.data.error,
                    isError: true,
                    isSuccess: true
                };
            }

            return {
                error: error.response.data.error,
                isError: true,
                isSuccess: false,
            };
        } else if (error.request) {
            // The request was made but no response was received
            console.error('NO RESPONSE RECEIVED:', error.message);

            return {
                error: 'No response received from server.',
                isError: true,
                isSuccess: false,
            };
        } else {
            console.error('REQUEST SETUP ERROR:', error.message);
            // Something else went wrong
            console.error('An unexpected error occurred.')
            return {
                error: 'An unexpected error occurred.',
                isError: true,
                isSuccess: false,
            };
        }
        //throw error.response.data.error
    }
}

export default dynamicAPI

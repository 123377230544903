import React, {useState, useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {confirmAlert} from 'react-confirm-alert'
import {useForm} from 'react-hook-form'
import useProductsHook from '../../../api/products'
import {ErrorBoundary} from "react-error-boundary";
import {useNavigate} from 'react-router-dom';
import { ViewProducts } from '../../../components/admin/products/ViewProducts';
import { FormProducts } from '../../../components/admin/products/FormProducts';
import {
    Spinner,
    Pagination,
    Message,
    Confirm,
} from '../../../components'
import {Button} from "@mui/material";
import useCategoriesHook from "../../../api/categories";
import useBrandsHook from "../../../api/brands";
import {checkImageFiles} from '../../../utils/imageTools';
import {useTranslation} from "react-i18next";

const Products = () => {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [edit, setEdit] = useState(false)
    const [q, setQ] = useState('')

    const {getProducts, postProduct, updateProduct, deleteProduct} = useProductsHook({
        page,
        q,
    })



    const {getCategories} = useCategoriesHook({
        limit: 100000,
        page: 1,
    })

    const {data: categoriesData} = getCategories;

    const {getBrands} = useBrandsHook({
        limit: 100000,
        page: 1,
    })

    const {data: brandsData} = getBrands;


    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
        // setError, // Add this line to include setError
        // clearErrors, // Include clearErrors if you'll use it
    } = useForm({
        defaultValues: {
            confirmed: true,
            blocked: false,
        },
    })

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getProducts

    const {
        // data: responseUpdate,
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        error: errorUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate,
    } = updateProduct

    const {
        // data: responseDelete,
        isLoading: isLoadingDelete,
        isError: isErrorDelete,
        error: errorDelete,
        isSuccess: isSuccessDelete,
        mutateAsync: mutateAsyncDelete,
    } = deleteProduct

    const {
        // data: responsePost,
        isLoading: isLoadingPost,
        isError: isErrorPost,
        error: errorPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost,
    } = postProduct

    const formCleanHandler = (force = false) => {
        if (force || isSuccessPost || isSuccessUpdate) {
            console.log('clean handler true');
            setEdit(false);
            reset();
            setFileInputs([]); // Also reset the file inputs state
            setImageThumbnails([]); // Also reset the image thumbnails
        }
    }

    const [imageThumbnails, setImageThumbnails] = useState([]);
    const [imagesPath, setImagesPath] = useState({});

    useEffect(() => {
        if (isSuccessPost || isSuccessUpdate) formCleanHandler()
    }, [isSuccessPost, isSuccessUpdate])

    useEffect(() => {
        refetch()
    }, [page])

    useEffect(() => {
        if (!q) refetch()
    }, [q])

    useEffect(() => {
        if (!q) refetch()
    }, [q])

    // useEffect(() => {
    //     console.log(data)
    //     if (data && data.images_path) {
    //         setImagesPath(data.images_path);
    //     }
    // }, [data]);


    const searchHandler = (e) => {
        e.preventDefault()
        refetch()
        setPage(1)
    }

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)))
    }
    const navigate = useNavigate();
    // const [imageData, setImageData] = useState({});

    // const updateImageThumbnails = (updatedThumbnails) => {
    //     // Assuming you have a state variable for imageThumbnails
    //     setImageThumbnails(updatedThumbnails);
    // };

    const [fileInputs, setFileInputs] = useState([]);


    const submitHandler = async (itemData) => {
        try {
            console.log('submitHandler');
            // console.log(itemData);

            console.log(fileInputs);

            if (edit) {

                const allImages = checkImageFiles(fileInputs);

                await mutateAsyncUpdate({
                    _id: id,
                    sku: itemData.sku,
                    barcode: itemData.barcode,
                    name: {
                        el: itemData.name_el,
                        en: itemData.name_en,
                    },
                    short_description: {
                        el: itemData.short_description_el,
                        en: itemData.short_description_en,
                    },
                    description: {
                        el: itemData.description_el,
                        en: itemData.description_en,
                    },
                    ingredients: {
                        el: itemData.ingredients_el,
                        en: itemData.ingredients_en,
                    },
                    allergens: {
                        el: itemData.allergens_el,
                        en: itemData.allergens_en,
                    },

                    // short_description: itemData.short_description,
                    // description: itemData.description,
                    price: itemData.price,
                    // price_per_kilo: itemData.price_per_kilo,
                    // price_with_tax: itemData.price_with_tax,
                    brand: itemData.brand,
                    sort_index: itemData.sort_index,
                    // ingredients: itemData.ingredients,
                    // allergens: itemData.allergens,
                    age_restriction: itemData.age_restriction,
                    age_restriction_message: itemData.age_restriction_message,
                    // size: itemData.size,
                    // category: itemData.category,
                    categories: itemData.categories,
                    images: allImages,
                    confirmed: itemData.confirmed,
                    blocked: itemData.blocked,
                    tags: itemData.tags,
                });
                console.log('RESPONSE UPDATE')
            } else {

                // Check and prepare images
                const preparedImages = checkImageFiles(fileInputs);

                const postPayload = {
                    // Include custom fields not present in itemData
                    name: {
                        el: itemData.name_el,
                        en: itemData.name_en,
                    },
                    short_description: {
                        el: itemData.short_description_el,
                        en: itemData.short_description_en,
                    },
                    description: {
                        el: itemData.description_el,
                        en: itemData.description_en,
                    },
                    ingredients: {
                        el: itemData.ingredients_el,
                        en: itemData.ingredients_en,
                    },
                    allergens: {
                        el: itemData.allergens_el,
                        en: itemData.allergens_en,
                    },
                    // Include the rest of itemData
                    ...itemData,
                    // Append the preparedImages
                    images: preparedImages,
                };

                console.log('RESPONSE POST')
                await mutateAsyncPost(postPayload);
            }

            // Request succeeded
            if (edit) {
                console.log('SUCCESSFULL EDIT');
            } else {
                console.log('SUCCESSFULL NEW');
            }
        } catch (error) {
            // Request failed
            console.error(error);
            if (edit) {
                console.log('FAILED EDIT POST')

            } else {
                console.log('FAILED NEW POST')
            }
            if (error.response && error.response.status === 500) {
                console.log('FAILED POST 500')
            }
        }
    };

    const editHandler = (product) => {
        console.log('editHandler');
        console.log(product);
        setId(product._id)
        setEdit(true);

        setValue('sku', product.sku);
        setValue('barcode', product.barcode);

        if(product.name && typeof product.name === 'object') {
            Object.keys(product.name).forEach(lang => {
                setValue(`name_${lang}`, product.name[lang]);
            });
        }

        if(product.short_description && typeof product.short_description === 'object') {
            Object.keys(product.short_description).forEach(lang => {
                setValue(`short_description_${lang}`, product.short_description[lang]);
            });
        }


        if(product.description && typeof product.description === 'object') {
            Object.keys(product.description).forEach(lang => {
                setValue(`description_${lang}`, product.description[lang]);
            });
        }

        if(product.ingredients && typeof product.ingredients === 'object') {
            Object.keys(product.ingredients).forEach(lang => {
                setValue(`ingredients_${lang}`, product.ingredients[lang]);
            });
        }

        if(product.allergens && typeof product.allergens === 'object') {
            Object.keys(product.allergens).forEach(lang => {
                setValue(`allergens_${lang}`, product.allergens[lang]);
            });
        }

        // setValue('short_description', product.short_description);
        // setValue('description', product.description);
        setValue('price', product.price);
        // setValue('price_per_kilo', product.price_per_kilo);
        // setValue('price_with_tax', product.price_with_tax);
        setValue('brand', product.brand);
        setValue('sort_index', product.sort_index);
        // setValue('ingredients', product.ingredients);
        // setValue('allergens', product.allergens);
        setValue('age_restriction', product.age_restriction);
        setValue('age_restriction_message', product.age_restriction_message);
        // setValue('size', product.size);
        // setValue('category', product.category);
        setValue('categories', product.categories);
        setValue('images', product.images);
        setImageThumbnails(product.images)
        setImagesPath(data.images_path);
        setValue('confirmed', product.confirmed);
        setValue('blocked', product.blocked);
        // setValue('tags', product.tags);
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Products</title>
                    <meta property='og:title' content='Products' key='title'/>
                </Helmet>
            </HelmetProvider>
            <ErrorBoundary
                FallbackComponent={() => (
                    <ErrorFallback goBack={() => navigate(-1)} error={isSuccessPost}/>
                )}
                onError={console.error}
            >
                {isErrorPost && <Message variant='danger'>{errorPost}</Message>}
                {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {isSuccessDelete && (
                    <Message variant='success'>Product has been deleted successfully.</Message>
                )}
                {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}
                {isSuccessUpdate && (
                    <Message variant='success'>Product has been updated successfully.</Message>
                )}
                {isSuccessPost && (
                    <Message variant='success'>Product has been Created successfully.</Message>
                )}


                <FormProducts
                    edit={edit}
                    formCleanHandler={formCleanHandler}
                    isLoading={isLoading}
                    isError={isError}
                    isErrorUpdate={isErrorUpdate}
                    errors={errors}
                    isLoadingUpdate={isLoadingUpdate}
                    isLoadingPost={isLoadingPost}
                    register={register}
                    handleSubmit={handleSubmit}
                    submitHandler={submitHandler}
                    // setImageData={setImageData}
                    watch={watch}
                    setValue={setValue}
                    error={error}
                    // updateImageThumbnails={updateImageThumbnails}
                    imageThumbnails={imageThumbnails}
                    images_path={imagesPath}
                    setFileInputs={setFileInputs}
                    fileInputs={fileInputs}
                    categoriesData={categoriesData}
                    brandsData={brandsData}
                    useTranslation={useTranslation}

                />

                <div className='ms-auto text-end'>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                {isLoading ? (
                    <Spinner/>
                ) : isError ? (
                    <Message variant='danger'>{error}</Message>
                ) : (
                    <ViewProducts
                        data={data}
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                        isLoadingDelete={isLoadingDelete}
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                        // images_path={imagesPath}
                        formCleanHandler={formCleanHandler}
                        useTranslation={useTranslation}


                    />
                )}
            </ErrorBoundary>

        </>
    )
}

function ErrorFallback({error, goBack}) {
    const errorMessage = error && error.message ? error.message : "Unknown error";

    return (
        <div>
            <div className="user-error-message-block">
                <p>Something went wrong 😭</p>
                <span>Error: {errorMessage}</span>
                <Button onClick={goBack}>Go Back</Button>
            </div>
        </div>
    );
}

export default Products

import dynamicAPI from './dynamicAPI';
import { useQuery } from 'react-query';

const url = '/api/auth/user-categories';

const useUserCategoriesHook = (props) => {
    const { page = 1, q = '', limit = 50 } = props;

    const getGuestCategories = useQuery(
        ['guestCategories', page, q, limit],
        async () =>
            await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`, {}),
        {
            retry: 0,
            refetchOnWindowFocus: false,  // Disable refetch on window focus
            staleTime: 1000 * 60 * 5,  // Cache for 5 minutes
        }
    );

    const getAdminCategories = useQuery(
        ['adminCategories', page, q, limit],
        async () =>
            await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`, {}),
        {
            retry: 0,
            refetchOnWindowFocus: false,  // Disable refetch on window focus
            staleTime: 1000 * 60 * 5,  // Cache for 5 minutes
        }
    );

    return {
        getGuestCategories,
        getAdminCategories,
    };
};

export default useUserCategoriesHook;

import moment from 'moment'
import {FaPenAlt, FaTrash} from 'react-icons/fa'
import {Search} from '../../index'
import DefaultCategoriesIcon from "../../../media/vending-machine.svg";

export const ViewBrands = ({
                                   data,
                                   editHandler,
                                   deleteHandler,
                                   isLoadingDelete,
                                   setQ,
                                   q,
                                   formCleanHandler,
                                   searchHandler,
                                   useTranslation
                               }) => {
    // console.log(data);
    const {t} = useTranslation(); // Use i18n instance to access current language

    return (
        <div className='table-responsive bg-light p-3 mt-2'>
            <div className='d-flex align-items-center flex-column mb-2'>
                <h3 className='fw-light text-muted'>
                    {t("Categories List")} <sup className='fs-6'> [{data && data.total}] </sup>
                    <img className="categories-list-title-icon admin-list-title-icon" src={DefaultCategoriesIcon}
                         alt="Default Marker Icon"/>
                </h3>
                <button
                    className='btn btn-outline-primary btn-sm shadow my-2'
                    data-bs-toggle='modal'
                    data-bs-target='#brandModal'
                    onClick={() => {
                        formCleanHandler(true);
                    }}
                >
                    {t("Add New Brand")}
                </button>
                <div className='col-auto'>
                    <Search
                        placeholder={t("Search by Name")}
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>
            <table className='table table-sm table-border edit-grid-view'>
                <thead className='border-0'>
                <tr>
                    <th></th>
                    <th>{t("Created")}</th>
                    <th>brand_id</th>
                    {/*<th>brandItems</th>*/}
                    <th>{t("Brand Name")}</th>
                    <th>{t("Actions")}</th>
                </tr>
                </thead>

                <tbody>
                {data && data.data ? (
                    data.data.map((brand) => (
                        <tr key={brand._id}
                            className={(brand.title === '' || brand.date === '' || brand.address === '') ? 'empty-values' : ''}>
                            {brand.images.length > 0 ? (
                                <td className="brand-list-thumbnail"><img
                                    src={`${data.images_path}${brand.images[0]['path']}`} alt="Thumbnail"/></td>
                            ) : (
                                <td className="brand-list-thumbnail"><img
                                    src={`${data.images_path}imall-item-no-image.png`} alt="Default Thumbnail"/></td>
                            )}
                            <td>{moment(brand.createdAt).format('lll')}</td>
                            <td>{brand.brand_id}</td>
                            <td>{brand.brand_name}</td>
                            <td>
                                <div className='btn-group'>
                                    <button
                                        className='btn btn-primary btn-sm rounded-pill'
                                        onClick={() => editHandler(brand)}
                                        data-bs-toggle='modal'
                                        data-bs-target='#brandModal'
                                    >
                                        <FaPenAlt/>
                                    </button>

                                    <button
                                        className='btn btn-danger btn-sm ms-1 rounded-pill'
                                        onClick={() => deleteHandler(brand._id)}
                                        disabled={isLoadingDelete}
                                    >
                                        {isLoadingDelete ? (
                                            <span className='spinner-border spinner-border-sm'/>
                                        ) : (
                                            <span>
                          <FaTrash/>
                        </span>
                                        )}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))) : (
                    // Render an error message when data is undefined or has no data
                    <tr>
                        <td colSpan="8">Error: Failed to fetch brand data</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

export default ViewBrands

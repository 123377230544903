import React, {useState, useEffect} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {confirmAlert} from 'react-confirm-alert';
import {useForm} from 'react-hook-form';
import useUserMachinesHook from '../../../api/userMachines';
import {ErrorBoundary} from 'react-error-boundary';
import ViewUserMachines from '../../../components/admin/userMachines/ViewUserMachines';
import FormUserMachines from '../../../components/admin/userMachines/FormUserMachines';
import useSimpleDataHook from '../../../api/simpleData';
import {
    Spinner,
    Pagination,
    Message,
    Confirm
} from '../../../components';

const UserMachines = () => {
    const [page, setPage] = useState(1);
    const [id, setId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [q, setQ] = useState('');
    const [triggerReset, setTriggerReset] = useState(false);
    const [selectedMachines, setSelectedMachines] = useState([]);

    const {getUserMachines, postUserMachine, updateUserMachine, deleteUserMachine} = useUserMachinesHook({
        page,
        q,
    })
    const {getSimpleUsers, getSimpleMachines} = useSimpleDataHook({
        limit: 100000, page: 1
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors}
    } = useForm({
        defaultValues: {
            auth: true,
        },
    });

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getUserMachines;
    const {data: dataUsers} = getSimpleUsers;
    const {data: dataMachines} = getSimpleMachines;

    const {
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        error: errorUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate
    } = updateUserMachine;

    const {
        isLoading: isLoadingDelete,
        isError: isErrorDelete,
        error: errorDelete,
        isSuccess: isSuccessDelete,
        mutateAsync: mutateAsyncDelete
    } = deleteUserMachine;

    const {
        isLoading: isLoadingPost,
        isError: isErrorPost,
        error: errorPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost
    } = postUserMachine;

    useEffect(() => {
        if (isSuccessPost || isSuccessUpdate) formCleanHandler();
    }, [isSuccessPost, isSuccessUpdate]);

    useEffect(() => {
        refetch();
    }, [page]);

    useEffect(() => {
        if (!q) refetch();
    }, [q]);

    const searchHandler = (e) => {
        e.preventDefault();
        refetch();
        setPage(1);
    };

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)));
    };

    const submitHandler = async (data) => {
        // console.log("Form Data:", data);
        try {
            if (edit) {
                console.log("Updating User Machine");
                await mutateAsyncUpdate({
                    _id: id,
                    user: data.user,
                    machines: data.machines,
                });
            } else {
                console.log("Creating User Machine");
                await mutateAsyncPost(data);
            }
        } catch (error) {
            console.error("Submission Error:", error);
        }
    };

    const formCleanHandler = () => {
        reset();
        setEdit(false);
        setId(null);
        setTriggerReset(!triggerReset);
        setSelectedMachines([]);
    };

    const editHandler = (userMachine) => {
        setId(userMachine._id);
        setEdit(true);

        // Set the user value in the form
        setValue('user', userMachine.user._id);

        // Map the machines to the format expected by the Select component and set the value
        const machines = userMachine.machines.map(machine => ({
            value: machine._id,
            label: machine.machine_name
        }));
        setSelectedMachines(machines);
        setValue('machines', machines);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>User Machines</title>
            </Helmet>
            <ErrorBoundary>
                <div className="ms-auto text-end">
                    <Pagination data={data} setPage={setPage}/>
                </div>
                {isLoading ? (
                    <Spinner/>
                ) : isError ? (
                    <Message variant="danger">{error}</Message>
                ) : (
                    <ViewUserMachines
                        data={data}
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                        isLoadingDelete={isLoadingDelete}
                        setQ={setQ}
                        q={q}
                        formCleanHandler={formCleanHandler}
                        searchHandler={searchHandler}
                    />
                )}
                {isErrorPost && <Message variant='danger'>{errorPost?.message || "An unexpected error occurred while creating the UserMachine."}</Message>}
                {isErrorUpdate && <Message variant='danger'>{errorUpdate?.message || "An unexpected error occurred while updating the UserMachine."}</Message>}
                {isErrorDelete && <Message variant='danger'>{errorDelete?.message || "An unexpected error occurred while deleting the UserMachine."}</Message>}

                {isSuccessDelete && (
                    <Message variant='success'>UserMachine has been deleted successfully.</Message>
                )}
                {isSuccessUpdate && (
                    <Message variant='success'>UserMachine has been updated successfully.</Message>
                )}
                {isSuccessPost && (
                    <Message variant='success'>UserMachine has been Created successfully.</Message>
                )}
                <FormUserMachines
                    edit={edit}
                    formCleanHandler={formCleanHandler}
                    isLoading={isLoading}
                    isError={isError}
                    errors={errors}
                    isLoadingUpdate={isLoadingUpdate}
                    isLoadingPost={isLoadingPost}
                    register={register}
                    handleSubmit={handleSubmit}
                    submitHandler={submitHandler}
                    watch={watch}
                    error={error}
                    dataUsers={dataUsers}
                    dataMachines={dataMachines}
                    setValue={setValue}
                    triggerReset={triggerReset}
                    selectedMachines={selectedMachines}
                    setSelectedMachines={setSelectedMachines}
                />
            </ErrorBoundary>
        </HelmetProvider>
    );
};

export default UserMachines;
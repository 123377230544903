import moment from 'moment'
import { FaPenAlt, FaTrash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { Search } from '../../index'
import DefaultProductsIcon from "../../../media/vending-machine.svg";

export const ViewProducts = ({
  data,
  editHandler,
  deleteHandler,
  formCleanHandler,
  isLoadingDelete,
  setQ,
  q,
  searchHandler,
  useTranslation
 // images_path
}) => {
  // console.log(data);
  const {i18n, t} = useTranslation(); // Use i18n instance to access current language


  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        <h3 className='fw-light text-muted'>
          {t("Products List")} <sup className='fs-6'> [{data && data.total}] </sup>
          <img className="products-list-title-icon admin-list-title-icon" src={DefaultProductsIcon} alt="Default Marker Icon" />
        </h3>
        <button
          className='btn btn-outline-primary btn-sm shadow my-2'
          data-bs-toggle='modal'
          data-bs-target='#productModal'
          onClick={() => formCleanHandler(true)}
        >
          {t("Add New Product")}
        </button>
        <div className='col-auto'>
          <Search
            placeholder={t("Search by SKU")}
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
        </div>
      </div>
      <table className='table table-sm table-border edit-grid-view'>
        <thead className='border-0'>
          <tr>
            <th></th>
            <th>{t("Created")}</th>
            <th>Barcode</th>
            <th>{t("Name")}</th>
            {/*<th>Items Errors</th>*/}
            {/*<th>Pre Items</th>*/}
            {/*<th>Post Items</th>*/}
            {/*<th>Pre Items Errors</th>*/}
            {/*<th>Post Items Errors</th>*/}
            <th>Confirmed</th>
            <th>Blocked</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>

        <tbody>
        {data && data.data ? (
            data.data.map((product) => (
                <tr key={product._id}
                    className={(product.title === '' || product.date === '' || product.address === '') ? 'empty-values' : ''}>
                  <td className='list-item-thumbnail'>
                    {product.images.length > 0 ? (
                        <td className="product-list-thumbnail"><img
                            src={`${data.images_path}${product.images[0]['path']}`} alt="Thumbnail"/></td>
                    ) : (
                        <td className="product-list-thumbnail"><img src={`${data.images_path}imall-item-no-image.png`}
                                                                    alt="Default Thumbnail"/></td>
                    )}
                  </td>
                  <td>{moment(product.createdAt).format('lll')}</td>
                  <td>{product.barcode}</td>
                  <td>{product.name?.[i18n.language] ?? product.name?.el}</td>

                  {/*<td>{JSON.stringify(product.items)}</td>*/}
                  {/*<td>{JSON.stringify(product.items_errors)}</td>*/}
                  {/*<td>{JSON.stringify(product.pre_items)}</td>*/}
                  {/*<td>{JSON.stringify(product.post_items)}</td>*/}
                  {/*<td>{JSON.stringify(product.pre_items_errors)}</td>*/}
                  {/*<td>{JSON.stringify(product.post_items_errors)}</td>*/}

                  <td>
                    {product.confirmed ? (
                        <FaCheckCircle className='text-success'/>
                    ) : (
                        <FaTimesCircle className='text-danger'/>
                    )}
                  </td>
                  <td>
                    {product.blocked ? (
                        <FaCheckCircle className='text-success'/>
                    ) : (
                        <FaTimesCircle className='text-danger'/>
                    )}
                  </td>

                  <td>
                    <div className='btn-group'>
                      <button
                          className='btn btn-primary btn-sm rounded-pill'
                          onClick={() => editHandler(product)}
                          data-bs-toggle='modal'
                          data-bs-target='#productModal'
                      >
                        <FaPenAlt/>
                      </button>

                      <button
                          className='btn btn-danger btn-sm ms-1 rounded-pill'
                          onClick={() => deleteHandler(product._id)}
                          disabled={isLoadingDelete}
                      >
                        {isLoadingDelete ? (
                            <span className='spinner-border spinner-border-sm'/>
                        ) : (
                            <span>
                          <FaTrash/>
                        </span>
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
            ))) : (
            // Render an error message when data is undefined or has no data
            <tr>
              <td colSpan="8">Error: Failed to fetch product data</td>
            </tr>
        )}
        </tbody>
      </table>
    </div>
  )
}

export default ViewProducts
